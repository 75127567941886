import * as React from "react"
import { Link, HeadFC, PageProps } from "gatsby"
import { FormattedMessage } from "react-intl"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <main style={pageStyles}>
          <h1 style={headingStyles}>
              <FormattedMessage id="notFoundTitle" defaultMessage="Page Not Found" />
          </h1>
        <p style={paragraphStyles}>
            <FormattedMessage id="notFoundDescription" defaultMessage="Sorry 😔, we couldn’t find what you were looking for." />
        
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
              <Link to="/">
                  <FormattedMessage id="links.goHome" defaultMessage="Go Home" />
              </Link>
      </p>
    </main>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>ScreenKeep - Not found</title>
